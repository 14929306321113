import { BoxProps } from '@chakra-ui/react';

import SectionColumn from './SectionColumn';
import SectionContent from './SectionContent';
import SectionRow from './SectionRow';

interface ISectionProps extends BoxProps {
  noPadding?: boolean;
}

export default function Section(props: ISectionProps) {
  const { children, height, ...rest } = props;

  return (
    <SectionRow height={height} {...rest}>
      <SectionColumn height={height}>
        <SectionContent height={height}>{children}</SectionContent>
      </SectionColumn>
    </SectionRow>
  );
}
