import { Rubik } from 'next/font/google';

// If loading a variable font, you don't need to specify the font weight
const rubik = Rubik({ subsets: ['latin'], display: 'swap' });

const typography = {
  fontWeights: {
    light: 200,
    less: 300,
    normal: 400,
    bold: 500,
    bolder: 600,
  },

  fonts: {
    body: `${rubik.style.fontFamily}, system-ui, sans-serif`,
    heading: 'inherit',
    color: 'blackAlpha.100',
  },

  fontSizes: {
    sm: '13px',
    base: '16px',
    md: '20px',
    lg: '16px',
    xl: '26px',
    xxl: '39.0625px',
    // equiyd
    'big-title': '44px',
    'medium-title': '32px',
    'small-title': '26px',
    subtitle: '20px',
    uppercase: '16px',
    label: '16px',
    paragraph: '15px',
    subscript: '13px',
  },
};

export type Typography = typeof typography;

export default typography;
