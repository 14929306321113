import { avatarAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(avatarAnatomy.keys);

const baseStyle = definePartsStyle({
  group: {},
  container: {
    borderColor: 'blackAlpha.10',
  },
});

export const avatarTheme = defineMultiStyleConfig({ baseStyle });
