import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const checkboxTheme = defineMultiStyleConfig({
  defaultProps: {
    colorScheme: 'purple',
  },
  baseStyle: definePartsStyle({
    control: {
      boxSize: '20px',
      borderRadius: '4px',
      borderColor: 'brand.purple',
    },
    container: {
      alignItems: 'center',
    },
    label: {
      lineHeight: '22px',
    },
  }),
});

export default checkboxTheme;
