import { useEffect, useState } from 'react';

import { ChevronDownIcon, ChevronRightIcon, CheckIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';

export enum DropdownOptionType {
  OPTION = 'OPTION',
  INPUT = 'INPUT',
}

export class DropDownOption {
  id: string;
  label: string;
  type?: DropdownOptionType;
  children?: DropDownOption[];

  constructor(
    id: string,
    label: string,
    type: DropdownOptionType = DropdownOptionType.OPTION,
    children?: DropDownOption[]
  ) {
    this.id = id;
    this.label = label;
    this.type = type;
    this.children = children;
  }
}

interface DropDownProps {
  isMandatory: boolean;
  options: DropDownOption[];
  title: string;
  selectedOptionId?: string;
  onSelect: (option: any) => void; // Allow array for multi-select
  config?: Config;
  dataCy?: string;
  disabled?: boolean;
  multiSelect?: boolean;
}

interface Config {
  customStyling?: Record<string, string>;
  placeHolderText?: string;
}

const DropDown: React.FC<DropDownProps> = ({
  isMandatory,
  options,
  title,
  selectedOptionId,
  onSelect,
  config,
  dataCy,
  disabled = false,
  multiSelect,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalBodyRef, setModalBodyRef] = useState<HTMLDivElement | null>(null);
  const [currentOptions, setCurrentOptions] = useState<DropDownOption[]>([]);
  const [navigationStack, setNavigationStack] = useState<DropDownOption[][]>(
    []
  );
  const [customInputValue, setCustomInputValue] = useState('');

  useEffect(() => {
    if (isOpen && modalBodyRef) {
      modalBodyRef.scrollTop = 0;
    }
  }, [isOpen, modalBodyRef]);
  const [selectedOptions, setSelectedOptions] = useState<DropDownOption[]>([]);

  useEffect(() => {
    if (isOpen) {
      const extendedOptions = isMandatory
        ? options
        : [...options, new DropDownOption('', 'Clear Selection')];
      setCurrentOptions(extendedOptions);
      setNavigationStack([]);
    }
  }, [isOpen, options, isMandatory]);

  const handleSelect = (option: DropDownOption) => {
    if (multiSelect) {
      setSelectedOptions((prev) => {
        const isAlreadySelected = prev.some(
          (selected) => selected.id === option.id
        );
        const updatedSelections = isAlreadySelected
          ? prev.filter((selected) => selected.id !== option.id) // Deselect if already selected
          : [...prev, option]; // Add to selected options

        onSelect(updatedSelections); // Trigger onSelect immediately
        return updatedSelections;
      });
    } else {
      if (option.type === DropdownOptionType.INPUT) {
        setCustomInputValue('');
      } else if (option.children && option.children.length > 0) {
        setNavigationStack([...navigationStack, currentOptions]);
        setCurrentOptions(option.children);
      } else {
        onSelect(option);
        setIsOpen(false);
      }
    }
  };

  const handleCustomInputSubmit = () => {
    if (customInputValue.trim() !== '') {
      const customOption = new DropDownOption(
        'custom-text-input',
        customInputValue,
        DropdownOptionType.OPTION
      );
      onSelect(customOption);
      setIsOpen(false);
      setCustomInputValue('');
    }
  };

  const handleBack = () => {
    const lastOptions = navigationStack.pop();
    if (lastOptions) {
      setCurrentOptions(lastOptions);
      setNavigationStack([...navigationStack]);
    }
  };

  const renderOptions = (optionsList: DropDownOption[]) => {
    return optionsList.map((option, index) => (
      <li key={index}>
        {option.type === DropdownOptionType.INPUT ? (
          <Box p={3}>
            <Text mb={2}>Enter custom value:</Text>
            <Input
              placeholder='Type here...'
              value={customInputValue}
              onChange={(e) => setCustomInputValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleCustomInputSubmit();
                }
              }}
              autoFocus
            />
            <Button mt={3} onClick={handleCustomInputSubmit} size='sm'>
              Submit
            </Button>
          </Box>
        ) : (
          <Box
            onClick={() => handleSelect(option)}
            cursor='pointer'
            display='flex'
            justifyContent='space-between'
            width='100%'
            alignItems='center'
            textAlign='left'
            borderBottom='1px solid #2121211A'
            py='md'
          >
            <Text flex={1} textAlign='left'>
              {option.label}
            </Text>
            {selectedOptions.some((selected) => selected.id === option.id) && (
              <CheckIcon color='green.500' />
            )}
            {option.children && option.children.length > 0 && (
              <ChevronRightIcon color='blackAlpha.30' />
            )}
          </Box>
        )}
      </li>
    ));
  };

  return (
    <>
      <InputGroup>
        <Input
          borderColor='blackAlpha.30'
          cursor='pointer'
          type='text'
          value={
            multiSelect
              ? selectedOptions.length > 0
                ? selectedOptions.map((option) => option.label).join(', ') // Join multi-select values with commas
                : config?.placeHolderText || 'Select'
              : selectedOptionId || config?.placeHolderText || 'Select'
          }
          readOnly
          onClick={() => setIsOpen(true)}
          onFocus={(e) => e.target.blur()}
          borderRadius='50px'
          color={
            selectedOptions.length > 0 || selectedOptionId
              ? 'blackAlpha.800'
              : 'blackAlpha.400'
          } // Darker color when there’s a selection
          sx={{ ...config?.customStyling }}
          data-cy={dataCy}
          disabled={disabled}
        />
        <InputRightElement pointerEvents='none' marginRight='1rem'>
          <ChevronDownIcon color='blackAlpha.30' />
        </InputRightElement>
      </InputGroup>

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent
          marginTop='auto'
          marginBottom='0'
          marginLeft='auto'
          marginRight='auto'
          width='100vw'
          maxWidth='100vw'
          bottom='0'
          position='fixed'
          height='auto'
        >
          <ModalBody
            padding={0}
            maxHeight='90vh'
            overflowY='auto'
            ref={(el) => el && setModalBodyRef(el)}
          >
            <Stack spacing={4} p={3}>
              <Center mb={4}>
                <Text fontWeight='bold'>{title}</Text>
              </Center>

              {navigationStack.length > 0 && (
                <Box position='absolute' top='10px' left='10px'>
                  <Button variant='link' onClick={handleBack}>
                    Back
                  </Button>
                </Box>
              )}

              <ul style={{ listStyleType: 'none', padding: 0 }}>
                {renderOptions(currentOptions)}
              </ul>

              <Button onClick={() => setIsOpen(false)}>Close</Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DropDown;
