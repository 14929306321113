import firebase, { getApps, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

import config from '@/config';

if (config.firebaseConfig && !getApps().length) {
  console.log('Initializing Firebase');
  initializeApp(config.firebaseConfig);
  console.log('Firebase initialized');
}

export const firebaseAuth = getAuth();

export default firebase;
