import React from 'react';

import { useTheme, chakra, Box, Flex, Button } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { useAuth } from '@/context/AuthContext';
import { Link } from '@/elements';

import useAutoHideHeader from './useAutoHideHeader';

const HeaderContainer = chakra(Box, {
  baseStyle: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 'overlay',
    display: 'flex',
    alignItems: 'center',
  },
});

const Logo = chakra(Box);

export default function Header() {
  const theme = useTheme();
  const navHeight = theme.header.height;

  const { isAuthenticated, signOut } = useAuth();

  const { nav } = useAutoHideHeader({
    navHeight,
  });

  return (
    <HeaderContainer
      ref={nav as React.MutableRefObject<HTMLElement>}
      boxShadow={'sm'}
      background='brand.white'
      height={`${navHeight}px`}
      p='md'
    >
      <Link href='/' mx='8px'>
        <Logo>Equiyd</Logo>
      </Link>

      <Flex flexGrow={1} justifyContent='flex-end'>
        {!isAuthenticated && (
          <Link href='/auth/signin' mx='8px'>
            Sign In
          </Link>
        )}
        {!isAuthenticated && (
          <Link href='/auth/register' mx='8px'>
            Register
          </Link>
        )}
        {isAuthenticated && (
          <Button
            variant='ghost'
            mx='8px'
            colorScheme='grey'
            onClick={() => signOut()}
          >
            <FormattedMessage
              id='layout.header.signout'
              defaultMessage={'Sign Out'}
            />
          </Button>
        )}
      </Flex>
    </HeaderContainer>
  );
}
