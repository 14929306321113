/* eslint-disable no-underscore-dangle */
import { FC } from 'react';

import { StoryblokComponent } from '@storyblok/react';

import { Text as TextContent } from '@/types.d';

const Text: FC<{
  content: TextContent;
}> = ({ content }) => <StoryblokComponent key={content._uid} blok={content} />;

export default Text;
