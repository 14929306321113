import { alertAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = definePartsStyle((props) => {
  const styles = {
    container: {
      paddingTop: '20px',
      paddingRight: '16px',
      paddingBottom: '16px',
      paddingLeft: '22px',
      backgroundColor: 'brand.white',
      border: '1px solid',
      borderColor: 'blackAlpha.30',
      borderRadius: '24px',
    },
    description: {
      fontSize: '15px',
      lineHeight: '22px',
    },
  } as any;

  if (props.status === 'info') {
    styles.icon = {
      color: 'red.500',
      marginRight: '14px',
    };
  }
  return styles;
});

const alertTheme = defineMultiStyleConfig({ baseStyle });

export default alertTheme;
