import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 'md',
  },
  container: {
    bg: 'brand.white',
    border: 0,
    px: 'md',
  },
  button: {
    py: 'md',
    px: 0,
    fontSize: 'lg',
    fontWeight: 'bold',
    _expanded: {
      borderBottom: '1px solid',
      borderBottomColor: 'grey.300',
    },
    _hover: {
      bg: 'inherit',
    },
  },
  panel: {
    px: 0,
  },
});

const accordionTheme = defineMultiStyleConfig({ baseStyle });

export default accordionTheme;
