import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  colorScheme: 'purple',
  field: {
    paddingInlineStart: { base: 'sm', md: 'md' },
    fontSize: 'base',
    colorScheme: 'purple',
    paddingTop: '2px',
    paddingX: 6,
    height: '48px',
    borderRadius: '24px',
    _placeholder: {
      position: 'relative',
      top: '1px',
      color: 'blackAlpha.30',
    },
    '::-ms-reveal': {
      display: 'none',
    },
    _focusVisible: {
      outline: 'none',
    },
  },
  icon: {
    color: 'blue.400',
  },
});

const defaultProps = {
  colorScheme: 'purple',
  focusBorderColor: 'brand.purple',
};

export const selectTheme = defineMultiStyleConfig({
  defaultProps,
  baseStyle,
});
