/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';

import styled from '@emotion/styled';
import {
  render,
  NODE_HEADING,
  MARK_LINK,
  NODE_IMAGE,
  NODE_PARAGRAPH,
} from 'storyblok-rich-text-react-renderer';

import config from '@/config';
import { H1, H2, H3, H4, P, Image, Link } from '@/elements';

import Placeholder from '../../support/Placeholder';

const Container = styled.div``;

export const defaultBlokResolver = (name: string) => {
  if (config.env === 'development') {
    return <Placeholder name={name} />;
  }
  // eslint-disable-next-line no-console
  console.error(
    new Error(`Storyblok component ${name} has not been implemented`)
  );
  return null;
};

const RichText: FC<{
  content: { body: any };
}> = ({ content }) => (
  <Container>
    {render(content.body, {
      markResolvers: {
        [MARK_LINK]: (
          children,
          { href, target }: { href: string; target: string }
        ) => (
          <Link href={href} target={target} variant='simple'>
            {children}
          </Link>
        ),
      },
      nodeResolvers: {
        [NODE_HEADING]: (children, { level }) => {
          switch (level) {
            case 1:
              return <H1>{children}</H1>;
            case 2:
              return <H2>{children}</H2>;
            case 3:
              return <H3>{children}</H3>;
            case 4:
              return <H4>{children}</H4>;
            default:
              return <P>{children}</P>;
          }
        },
        [NODE_IMAGE]: (children, { src, alt, title }) => (
          <Image alt={alt} src={src} title={title} />
        ),
        [NODE_PARAGRAPH]: (children) => <P>{children}</P>,
        // [NODE_OL]: children => <Ol variant='withPadding'>{children}</Ol>,
        // [NODE_UL]: children => <Ul variant='withPadding'>{children}</Ul>,
        // [NODE_LI]: children => <Li>{children}</Li>,
      },
      defaultBlokResolver,
    })}
  </Container>
);

export default RichText;
