import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const baseStyle = definePartsStyle({
  tab: {
    _selected: {
      color: 'blackAlpha.100',
      fontWeight: 'bold',
    },
  },
  indicator: {
    background: 'brand.purple',
    height: '3px',
  },
});

const unstyled = definePartsStyle(() => ({
  tab: {
    color: 'blackAlpha.80',
    fontSize: '16px',
    lineHeight: '20px',
    paddingX: '12px',
    paddingTop: 0,
    paddingBottom: '16px',
  },
  indicator: {
    background: 'brand.purple',
    height: '3px',
  },
}));

const bubble = definePartsStyle(() => ({
  root: {
    backgroundColor: 'purple',
    paddingX: 2,
  },
  tab: {
    color: 'whiteAlpha.900',
    fontSize: '16px',
    lineHeight: '20px',
    paddingY: '12px',
    paddingX: 0,
    borderRadius: '60px',
    _selected: {
      color: 'blackAlpha.100',
      fontWeight: 'bold',
      zIndex: 1,
    },
    flex: 1,
  },
  tablist: {
    backgroundColor: 'whiteAlpha.200',
    padding: '2px',
    borderRadius: '60px',
  },
  tabpanel: {
    padding: 0,
  },
  indicator: {
    background: 'brand.white',
    height: '44px',
    borderRadius: '60px',
    top: '5px',
  },
}));

const brand = definePartsStyle(() => ({
  tab: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'grey.500',
    lineHeight: '20px',
    _selected: {
      color: 'brand.purple',
      borderColor: 'brand.purple',
      borderBottom: '2px',
      pb: '4px',
      mb: '-1px',
    },
  },
  tablist: {
    gap: '20px',
    px: 'md',
    pb: 'm',
    mb: 'md',
    bg: 'brand.white',
    overflowX: 'auto',
    overflowY: 'hidden',
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

const tabsTheme = defineMultiStyleConfig({
  defaultProps: {
    colorScheme: 'purple',
  },
  baseStyle,
  variants: {
    unstyled,
    bubble,
    brand,
  },
});

export default tabsTheme;
