import { StarIcon } from '@chakra-ui/icons';
import { Box, Card, IconButton, useDisclosure } from '@chakra-ui/react';

import { H2 } from '@/elements';

import Impersonate from './Impersonate';

const AdminPanel = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const offsetLeft = isOpen ? `-50px` : `100%`;

  return (
    <>
      <IconButton
        aria-label='admin-button'
        icon={<StarIcon />}
        onClick={() => {
          isOpen ? onClose() : onOpen();
        }}
        position={`fixed`}
        bottom={`20px`}
        right={`20px`}
      />
      <Box
        right={0}
        bottom={'100px'}
        width='320px'
        transition={`all 0.3s`}
        transform={`translate(${offsetLeft}, 0)`}
        position={`fixed`}
        bg={'white'}
        zIndex={999}
        shadow={'lg'}
        borderRadius={'12px'}
      >
        <Card padding={3} borderRadius={'12px'}>
          <H2 mb='lg' fontSize='24px'>
            Admin Panel
          </H2>
          <Box>
            <Impersonate />
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default AdminPanel;
