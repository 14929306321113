import { FC } from 'react';

import { Box } from '@chakra-ui/react';

const Placeholder: FC<{
  name: string;
}> = ({ name }) => (
  <Box maxW='100%'>
    The component <strong>{name}</strong> has not been created yet.
  </Box>
);

export default Placeholder;
