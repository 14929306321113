import { createContext, useContext, useEffect } from 'react';

import Intercom, { shutdown } from '@intercom/messenger-js-sdk';

import config from '@/config';

import { useAuth } from './AuthContext';

interface IntercomContextProps {}

const IntercomContext = createContext<IntercomContextProps | undefined>(
  undefined
);

export const useIntercom = () => {
  const context = useContext(IntercomContext);
  if (context === undefined) {
    throw new Error('useIntercom must be used within an IntercomProvider');
  }
  return context;
};

export const IntercomProvider = ({ children }) => {
  const { self } = useAuth();

  useEffect(() => {
    if (self?.email) {
      Intercom({
        app_id: config.intercomAppId,
        user_id: self.id.toString(),
        email: self.email,
        name: `${self.profile?.firstName} ${self.profile?.lastName}`,
      });
    }
    const style = document.createElement('style');
    style.innerHTML = `

      @media (max-width: 1024px) {
      .intercom-lightweight-app-launcher {
      bottom: 100px !important;
      }
      
      /*localhost*/
      .intercom-dfosxs {
      bottom: 100px !important;
      }
      
      iframe[name='intercom-notifications-frame'] {
      bottom: 124px !important;
      }
      }
    `;
    document.head.appendChild(style);

    if (!self) {
      shutdown();
    }
  }, [self]);

  return (
    <IntercomContext.Provider value={{}}>{children}</IntercomContext.Provider>
  );
};
