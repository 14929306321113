/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import { FC } from 'react';

import { storyblokEditable, StoryblokComponent } from '@storyblok/react';

const BlogPost: FC<any> = ({ blok }) => (
  <main {...storyblokEditable(blok)} key={blok._uid}>
    {blok.body.map((nestedBlok) => (
      <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />
    ))}
  </main>
);

export default BlogPost;
