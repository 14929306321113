import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';

import { useRouter } from 'next/router';
import {
  signIn as nextSignIn,
  signOut as nextSignOut,
  useSession,
} from 'next-auth/react';

import AdminPanel from '@/components/AdminPanel';
import { User, UserRole, useSelfLazyQuery } from '@/generated/core.graphql';
import useCustomSession from '@/hooks/customSession';
import { CustomSession, SessionUser } from '@/server/models/auth';

import { useTracking } from './TrackingContext';

interface IAuthContext {
  isLoading: boolean;
  isAuthenticated: boolean;
  isSigningIn: boolean;
  session?: CustomSession;
  self?: User | undefined;
  selfError?: string;
  roles?: UserRole[] | undefined;
  signInError?: string;
  signIn: (email: string, password: string) => void;
  signOut: (redirect?: string) => void;
  refetchSelf: () => void;
  updateSession: () => Promise<SessionUser>;
}

const AuthContext = React.createContext<IAuthContext>({
  isLoading: true,
  isAuthenticated: false,
  isSigningIn: false,
  signIn: () => null,
  signOut: () => null,
  refetchSelf: () => null,
  updateSession: () => null,
});

function AuthProvider({
  children,
  clearCache,
}: {
  clearCache: () => void;
  children: React.ReactNode;
}) {
  const { update } = useSession();
  const router = useRouter();
  const session = useCustomSession();
  const sessionUser = session.data?.user;
  const { identify, reset } = useTracking();

  const [isSigningIn, setIsSigningIn] = useState(false);
  const [signInError, setSignInError] = useState<string | undefined>('');

  const [
    getSelf,
    { data: selfData, error: selfError, refetch, loading: loadingSelf },
  ] = useSelfLazyQuery();

  useEffect(() => {
    if (
      selfData?.self &&
      !selfData?.self.profile?.firstName &&
      !router.pathname.startsWith('/auth/verify')
    ) {
      router.push('/onboarding/profile');
    }
  }, [selfData]);

  useEffect(() => {
    const roles = selfData?.self?.roles || [];
    if (roles.length > 0) {
      let refreshRoles = false;
      roles.map((role) => {
        const matchingRole = sessionUser?.roles?.find(
          (x) => x.toUpperCase() === role.toUpperCase()
        );

        if (!matchingRole) {
          console.log('role mismatch');
          refreshRoles = true;
        }
      });

      if (sessionUser?.roles) {
        sessionUser.roles.map((role) => {
          const matchingRole = roles.find(
            (x) => x.toUpperCase() === role.toUpperCase()
          );

          if (!matchingRole) {
            console.log('role mismatch');
            refreshRoles = true;
          }
        });
      }

      if (refreshRoles) {
        console.log('refreshing roles');
        session.update();
      }
    }
  }, [selfData?.self?.roles]);

  useEffect(() => {
    if (sessionUser && !selfData?.self) {
      getSelf();
    }
  }, [sessionUser, selfData, getSelf]);

  useEffect(() => {
    if (selfData?.self) {
      identify(selfData.self as User);
    }
  }, [selfData]);

  const updateSession = useCallback(async () => {
    const updatedSession = await update();
    return updatedSession?.user as SessionUser;
  }, [update]);

  const signIn = useCallback(
    async (email: string, password: string) => {
      setSignInError(undefined);
      setIsSigningIn(true);

      try {
        const redirectUrl = router.query.redirect as string;
        const result = await nextSignIn('credentials', {
          username: email,
          password,
          // handle signin response without redirect
          // (available only for credentials and email providers)
          redirect: false,
        }); // adds CSRF token automatically
        if (result.ok && !result.error) {
          const response = await getSelf();

          identify(response.data.self as User);

          if (response.data?.self.profile?.firstName) {
            router.push(redirectUrl || '/');
          } else {
            if (redirectUrl) {
              window.localStorage.setItem('redirectUrl', redirectUrl);
            }
            router.push(`/onboarding/profile`);
          }
        } else {
          setSignInError('Invalid credentials. Check your email or password.');
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('signin error:', err);
        setSignInError(err.message);
      } finally {
        setIsSigningIn(false);
      }
    },
    [router]
  );

  const signOut = useCallback(
    async (redirect) => {
      try {
        await reset();
        await clearCache();

        await nextSignOut({
          callbackUrl: redirect || '/auth/signin',
          redirect: true, // stay on this page
        });

        await getSelf();

        // eslint-disable-next-line no-console
        console.log('signed out.');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('signout error:', err);
      }
    },
    [clearCache, getSelf]
  );

  const refetchSelf = useCallback(async () => {
    if (refetch) {
      await refetch();
    }
  }, [refetch]);

  const isAdmin = sessionUser?.roles?.find(
    (x) => x.toUpperCase() === UserRole.Admin.toUpperCase()
  );

  return (
    <AuthContext.Provider
      value={{
        isLoading: loadingSelf || session.isLoading || isSigningIn,
        isAuthenticated: !!session.data?.user,
        isSigningIn,
        session: session.data,
        self: selfData?.self as User,
        selfError: selfError?.message,
        roles: sessionUser?.roles || [],
        signInError,
        signIn,
        signOut,
        refetchSelf,
        updateSession,
      }}
    >
      {isAdmin && <AdminPanel />}
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider.');
  }
  return context;
}

export { AuthProvider, useAuth };
