import { Box } from '@chakra-ui/react';

export default function SectionRow({ children, noPadding = false, ...rest }) {
  const padding = noPadding
    ? 0
    : {
        base: 'md',
        sm: 'md',
        md: 'lg',
        lg: 'xl',
      };
  return (
    <Box className='section-row' px={padding} width='100%' {...rest}>
      {children}
    </Box>
  );
}
