/* eslint-disable no-underscore-dangle */
import React, { FC } from 'react';

import { VStack } from '@chakra-ui/react';
import { storyblokEditable } from '@storyblok/react';

import { Section } from '@/components/Layout';
import { RichTextSection } from '@/types.d';

import { RichText } from '../../components';

const Content: FC<{
  blok: RichTextSection;
}> = ({ blok }) => (
  <section {...storyblokEditable(blok)} key={blok._uid}>
    <Section>
      <VStack alignItems='left' maxWidth='800px' mb='xl' mx='auto' spacing='md'>
        <RichText content={blok} />
      </VStack>
    </Section>
  </section>
);

export default Content;
