import { useState, useCallback, useEffect } from 'react';

import { useApolloClient } from '@apollo/client';
import {
  NumberInput,
  NumberInputField,
  Button,
  Box,
  Text,
} from '@chakra-ui/react';

import { H3 } from '@/elements';

const format = (id: string | number) => {
  const idNum = Number(id);
  if (Number.isNaN(idNum)) {
    return '';
  }

  return idNum;
};

const Impersonate = () => {
  const [impersonated, setImpersonated] = useState(false);
  const [impersonatedId, setImpersonatedId] = useState<number>();
  const client = useApolloClient();

  const impersonate = useCallback(async () => {
    if (!impersonatedId) {
      return;
    }

    setImpersonatedId(impersonatedId);
    setImpersonated(true);
    localStorage.setItem('impersonate', `${impersonatedId}`);
    window.location.reload();
  }, [impersonatedId]);

  const reset = useCallback(async () => {
    localStorage.removeItem('impersonate');
    setImpersonatedId(undefined);
    setImpersonated(false);
    await client.clearStore();
    window.location.reload();
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const currentImpersonatedId = localStorage.getItem('impersonate');
      if (currentImpersonatedId) {
        setImpersonatedId(Number(currentImpersonatedId));
        setImpersonated(true);
      }
    }
  }, []);

  return (
    <>
      <H3 mb='md'>Impersonation</H3>
      <Box alignItems='center' display='flex' gridGap={3}>
        {impersonated ? (
          <>
            <Text>
              Impersonating user <strong>{impersonatedId}</strong>
            </Text>
            <Button
              fontSize='12px'
              onClick={reset}
              padding='10px'
              height='32px'
            >
              Reset
            </Button>
          </>
        ) : (
          <>
            <NumberInput size='sm' value={format(impersonatedId)}>
              <NumberInputField
                minHeight='auto'
                onChange={(e) => setImpersonatedId(Number(e.target.value))}
                placeholder='User id'
              />
            </NumberInput>
            <Button
              fontSize='12px'
              onClick={impersonate}
              padding='10px'
              height='32px'
              disabled={!impersonatedId}
            >
              Impersonate
            </Button>
          </>
        )}
      </Box>
    </>
  );
};

export default Impersonate;
