import { Flex } from '@chakra-ui/react';

export const DottedSpacer = ({ showSpacerDot = false, ...props }) => {
  return (
    <Flex
      sx={{
        flex: 1,
        _after: {
          content: '""',
          borderBottom: showSpacerDot ? '2px dotted' : '0',
          flex: 1,
          margin: '7px .5em',
          borderColor: '#2121213D',
        },
      }}
      {...props}
    />
  );
};
