export default {
  red: {
    '500': '#EB4C80',
  },
  green: {
    '500': '#3A8443',
  },
  purple: {
    '50': '#ECE5F5',
    '100': '#D2C2E8',
    '200': '#AB95CA',
    '300': '#8F72B9',
    '400': '#734EA7',
    '500': '#572B96',
    '600': '#49247D',
    '700': '#3A1D64',
    '800': '#2C164B',
    '900': '#1D0E32',
  },
  blackAlpha: {
    100: '#212121',
    80: '#212121CC',
    60: '#21212160',
    30: '#2121214D',
    10: '#2121211A',
  },
  brand: {
    purple: '#572B96',
    white: '#FFFFFF',
    green: '#3A8443',
    orange: '#D48527',
    red: '#C34D4D',
    yellow: '#BEA523',
  },
  grey: {
    '50': '#F9FAFB',
    '100': '#F2F4F7',
    '200': '#E4E7EC',
    '300': '#D0D5DD',
    '400': '#98A2B3',
    '500': '#667085',
    '600': '#475467',
    '700': '#344054',
    '800': '#182230',
    '900': '#101828',
  },
  error: {
    '50': '#FBDBE6',
    '100': '#F8C3D5',
    '200': '#F5A5BF',
    '300': '#F288AA',
    '400': '#EE6A95',
    '500': '#EB4C80',
    '600': '#C43F6B',
    '700': '#9D3355',
    '800': '#762640',
    '900': '#4E192B',
  },
  success: {
    '50': '#D8E6D9',
    '100': '#BDD6C0',
    '200': '#9CC1A1',
    '300': '#7CAD82',
    '400': '#5B9862',
    '500': '#3A8443',
    '600': '#306E38',
    '700': '#27582D',
    '800': '#1D4222',
    '900': '#132C16',
  },
};
