import { chakra, Box } from '@chakra-ui/react';

const SectionContentContainer = chakra(Box, {
  baseStyle: {
    width: '100%',
    maxWidth: 'lg',
    margin: '0 auto',
    position: 'relative',
  },
});

export default function SectionContent({ children, ...rest }) {
  return (
    <SectionContentContainer {...rest}>{children}</SectionContentContainer>
  );
}
