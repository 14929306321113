import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  field: {
    paddingInlineStart: { base: 'sm', md: 'md' },
    fontSize: 'base',
    colorScheme: 'purple',
    paddingTop: '2px',
    paddingX: 'mmd',
    paddingY: 0,
    height: '48px',
    _placeholder: {
      position: 'relative',
      top: '1px',
      color: 'blackAlpha.30',
    },
    '::-ms-reveal': {
      display: 'none',
    },
  },
});

const defaultProps = {
  colorScheme: 'purple',
  focusBorderColor: 'brand.purple',
};

const inputTheme = defineMultiStyleConfig({
  defaultProps,
  baseStyle,
  variants: {
    outline: {
      field: {
        borderColor: 'blackAlpha.30',
        borderRadius: '50px',
      },
    },
  },
});

export default inputTheme;
