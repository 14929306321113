import { sliderAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);
const baseStyle = definePartsStyle({
  thumb: {
    bg: 'brand.purple',
    border: '2px solid',
    borderColor: 'brand.white',
    boxSize: '32px',
  },
  filledTrack: {
    bg: 'brand.purple',
    border: '1px solid',
    borderColor: 'brand.purple',
    borderRadius: '30px',
  },
  track: {
    height: '12px',
    borderRadius: '30px',
    background: 'brand.white',
    border: '1px solid',
    borderColor: 'blackAlpha.30',
    backgroundImage:
      'repeating-linear-gradient(to right, var(--chakra-colors-blackAlpha-30), var(--chakra-colors-blackAlpha-30) 1px, white 1px, white)',
    backgroundSize: '20% 20%',
    overflow: 'visible',
  },
});

const sliderTheme = defineMultiStyleConfig({ baseStyle });

export default sliderTheme;
