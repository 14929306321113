import { useSession } from 'next-auth/react';

import { CustomSession } from '@/server/models/auth';

export default function useCustomSession() {
  const sessionOptions = useSession();
  const session = sessionOptions.data as CustomSession;
  const isLoading = sessionOptions.status === 'loading';

  return {
    data: session,
    status: sessionOptions.status,
    isLoading,
    update: sessionOptions.update,
  };
}
