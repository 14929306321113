import { defineStyleConfig } from '@chakra-ui/react';

import { labelMedium } from './text';

const formLabelTheme = defineStyleConfig({
  baseStyle: {
    fontSize: 'label',
    fontWeight: 'normal',
  },
  defaultProps: {
    colorScheme: 'purple',
  },
  variants: {
    labelMedium,
  },
});

export default formLabelTheme;
