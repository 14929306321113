import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const bigTitle = defineStyle(() => ({
  fontSize: '44px',
  lineHeight: '56px',
}));

const mediumTitle = defineStyle(() => ({
  fontSize: '32px',
  lineHeight: '44px',
}));

export const smallTitle = defineStyle(() => ({
  fontSize: '26px',
  lineHeight: '32px',
}));

const uppercase = defineStyle(() => ({
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 600,
  textTransform: 'uppercase',
}));

const subtitleBold = defineStyle(() => ({
  fontSize: '20px',
  lineHeight: '28px',
  fontWeight: 500,
}));

const subtitleMedium = defineStyle(() => ({
  fontSize: '20px',
  lineHeight: '28px',
  fontWeight: 400,
}));

const labelBold = defineStyle(() => ({
  fontSize: '16px',
  lineHeight: '20px',
  fontWeight: 'bold',
}));
export const labelMedium = defineStyle(() => ({
  fontSize: '16px',
  lineHeight: '20px',
  fontWeight: 'normal',
}));
const labelLight = defineStyle(() => ({
  fontSize: '16px',
  lineHeight: '20px',
}));

const paragraphBold = defineStyle(() => ({
  fontSize: '15px',
  lineHeight: '22px',
  fontWeight: 'bold',
}));
const paragraphMedium = defineStyle(() => ({
  fontSize: '15px',
  lineHeight: '22px',
  color: 'blackAlpha.80',
}));

const subscriptBold = defineStyle(() => ({
  fontSize: '13px',
  fontWeight: 'bolder',
  lineHeight: '18px',
}));
const subscriptMedium = defineStyle(() => ({
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '18px',
}));
const subscriptLight = defineStyle(() => ({
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '18px',
}));

const textTheme = defineStyleConfig({
  defaultProps: {
    colorScheme: 'purple',
  },
  variants: {
    bigTitle,
    mediumTitle,
    smallTitle,
    uppercase,

    subtitleBold,
    subtitleMedium,

    labelBold,
    labelMedium,
    labelLight,

    paragraphBold,
    paragraphMedium,

    subscriptBold,
    subscriptMedium,
    subscriptLight,
  },
});

export default textTheme;
