import {
  getAuth,
  signInWithEmailAndPassword,
  AuthErrorCodes,
  UserCredential,
  User as FirebaseUser,
} from 'firebase/auth';
import { jwtDecode } from 'jwt-decode';
import { NextApiRequest, NextApiResponse } from 'next';
import NextAuth, {
  Account,
  NextAuthOptions,
  User as NextAuthUser,
} from 'next-auth';
import CredentialsProvider from 'next-auth/providers/credentials';

import config from '@/config';
import { UserRole } from '@/generated/core.graphql';
import { firebaseAuth } from '@/lib/configure/firebase';
import { CustomSession, SessionUser } from '@/server/models/auth';

// Note:
// - Firebase idToken lifetime is 1h
// - Refresh tokens expire only when one of the following occurs
//  - The user is deleted
//  - The user is disabled
//  - A major account change is detected for the user. This includes events like password or email address updates.

type AppUser = NextAuthUser & {
  firebaseId: string;
  displayName: string;
  roles: string[];
  idToken: string;
  refreshToken: string;
  emailVerified: boolean;
};

interface IRefreshTokensResponse {
  access_token: string;
  expires_in: string;
  token_type: string;
  refresh_token: string;
  id_token: string;
  user_id: string;
  project_id: string;
}

/** Returns ISO date when token expires */
function getIdTokenExpiration(idToken: string): string {
  const decoded: any = jwtDecode(idToken);
  return new Date(decoded.exp * 1000).toISOString();
}

/**
 * Exchanges refreshToken for an idToken by calling Firebase REST API. Also, returns accessToken.
 * https://firebase.google.com/docs/reference/rest/auth#section-refresh-token
 */
async function refreshTokens(refreshToken: string) {
  try {
    const url = `https://securetoken.googleapis.com/v1/token?key=${encodeURI(
      config.firebaseConfig.apiKey
    )}`;

    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      method: 'POST',
      body: new URLSearchParams({
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      }),
    });
    const responseData = await response.json();

    if (!response.ok) {
      throw responseData;
    }

    const refreshResult = responseData as IRefreshTokensResponse;

    return {
      idToken: refreshResult.id_token,
      idTokenExpiresAt: new Date(
        Date.now() + parseInt(refreshResult.expires_in, 10) * 1000
      ).toISOString(),
      refreshToken: refreshResult.refresh_token,
    };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('tokens refresh error:', err);
    throw new Error('Tokens refresh error.');
  }
}

async function getAppUserFromFirebaseUser(
  fbUser: FirebaseUser,
  updateToken = false
): Promise<AppUser> {
  let { idToken } = fbUser as any;

  if (updateToken || !idToken) {
    idToken = await fbUser.getIdToken(updateToken);
  }

  // get claims from idToken
  const idTokenDecoded: any = jwtDecode(idToken);

  const firebaseUser: AppUser = {
    id: idTokenDecoded.app_user_id,
    firebaseId: fbUser.uid,
    displayName: fbUser.displayName,
    email: fbUser.email,
    roles: idTokenDecoded.roles || [],
    idToken,
    refreshToken: fbUser.refreshToken,
    emailVerified: fbUser.emailVerified,
  };

  return firebaseUser;
}

async function getFireBaseUserForProvider(
  account: Account,
  user: NextAuthUser | AppUser | FirebaseUser
): Promise<AppUser> {
  let fbUser: FirebaseUser;

  if (account.provider === 'credentials') {
    fbUser = user as FirebaseUser;
  }

  if (!fbUser) {
    throw new Error('Firebase login error. No FB User found');
  }

  return getAppUserFromFirebaseUser(fbUser);
}

export const authOptions: NextAuthOptions = {
  debug: true,
  session: {
    // use JWT for session. Session Cookie 'next-auth.session-token' value will be generated JWT.
    strategy: 'jwt',

    // Seconds - How long until an idle session expires and is no longer valid.
    // Note: this applies to session cookie expiration, but looks like not to 'exp' claim of JWT
    maxAge: 30 * 24 * 60 * 60, // 30 days
  },
  secret: config.nextAuthJwtSecret,
  pages: {
    signIn: '/auth/signin',
    signOut: '/auth/signin',
    error: '/auth/error', // Error code passed in query string as ?error=
  },
  providers: [
    CredentialsProvider({
      // The name to display on the sign in form (e.g. "Sign in with...")
      name: 'Sign in with credentials',
      // The credentials is used to generate a suitable form on the sign in page.
      // You can specify whatever fields you are expecting to be submitted.
      // e.g. domain, username, password, 2FA token, etc.
      // You can pass any HTML attribute to the <input> tag through the object.
      credentials: {
        username: { label: 'Email', type: 'email', placeholder: '' },
        password: { label: 'Password', type: 'password' },
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      async authorize(credentials, req2) {
        // Add logic here to look up the user from the credentials supplied

        // Any object returned will be saved in `user` property of the JWT
        // If you return null then an error will be displayed advising the user to check their details.
        // You can also Reject this callback with an Error thus the user will be sent to the error page with the error message as a query parameter
        let response: UserCredential;
        try {
          response = await signInWithEmailAndPassword(
            firebaseAuth,
            credentials.username,
            credentials.password
          );
        } catch (err) {
          const errorCode = err.code;
          const errorMessage = err.message;
          // eslint-disable-next-line no-console
          console.error('firebase signin error:', errorCode, errorMessage);
          if (errorCode === AuthErrorCodes.INVALID_PASSWORD) {
            return null;
          }

          throw new Error('Credentials login error.');
        }

        const idToken = await response.user.getIdToken();

        // get claims from idToken
        const idTokenDecoded: any = jwtDecode(idToken);

        const firebaseUser: AppUser = {
          id: idTokenDecoded.app_user_id,
          firebaseId: response.user.uid,
          displayName: response.user.displayName,
          email: response.user.email,
          roles: idTokenDecoded.roles || [],
          idToken,
          refreshToken: await response.user.refreshToken,
          emailVerified: response.user.emailVerified,
        };
        return firebaseUser;
      },
    }),
  ],
  callbacks: {
    // control if a user is allowed to sign in
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async signIn({ user, account, profile, email, credentials }) {
      return true;
    },

    // The redirect callback is called anytime the user is redirected to a callback URL (e.g. on signin or signout).
    async redirect({ url, baseUrl }) {
      console.log(url, baseUrl);
      return url.startsWith(baseUrl) ? url : `${baseUrl}${url}`;
    },

    // This callback is called whenever a JSON Web Token is created (i.e. at sign in) or updated (i.e whenever a session is accessed in the client)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async jwt({ token, user, account, profile, trigger }) {
      const isInitialSignIn = account && user;
      if (isInitialSignIn) {
        // user is passed from credentials provider 'authorize' function
        const firebaseUser = await getFireBaseUserForProvider(account, user);

        const sessionUser: SessionUser = {
          id: firebaseUser.id,
          firebaseId: firebaseUser.firebaseId as string,
          email: firebaseUser.email,
          name: firebaseUser.displayName as string,
          roles: firebaseUser.roles as UserRole[],
          emailVerified: firebaseUser.emailVerified,
        };

        const idToken = firebaseUser.idToken?.toString();

        /* eslint-disable no-param-reassign */
        token.idToken = idToken;
        token.idTokenExpiresAt = getIdTokenExpiration(idToken);
        token.refreshToken = firebaseUser.refreshToken;
        token.sessionUser = {
          ...sessionUser,
        };
        /* eslint-enable no-param-reassign */
      }

      if (trigger === 'update') {
        const user = getAuth().currentUser;
        if (user) {
          await user.reload();
        }

        // user is passed from credentials provider 'authorize' function
        const firebaseUser = await getAppUserFromFirebaseUser(user, true);

        const sessionUser: SessionUser = {
          id: firebaseUser.id,
          firebaseId: firebaseUser.firebaseId as string,
          email: firebaseUser.email,
          name: firebaseUser.displayName as string,
          roles: firebaseUser.roles as UserRole[],
          emailVerified: firebaseUser.emailVerified,
        };

        const idToken = firebaseUser.idToken?.toString();

        /* eslint-disable no-param-reassign */
        token.idToken = idToken;
        token.idTokenExpiresAt = getIdTokenExpiration(idToken);
        token.refreshToken = firebaseUser.refreshToken;
        token.sessionUser = {
          ...sessionUser,
        };
      }

      // refresh idToken 10 mins before it expires
      // (user will be signed out if refresh fail)
      const refreshBeforeExpiryMs = 10 * 60 * 1000;
      if (
        new Date(token.idTokenExpiresAt?.toString()).getTime() -
          refreshBeforeExpiryMs <=
        Date.now()
      ) {
        const refreshResult = await refreshTokens(
          token.refreshToken.toString()
        );

        /* eslint-disable no-param-reassign */
        token.idToken = refreshResult.idToken;
        token.idTokenExpiresAt = refreshResult.idTokenExpiresAt;
        token.refreshToken = refreshResult.refreshToken;
        /* eslint-enable no-param-reassign */
      }

      return token;
    },

    // The session callback is called whenever a session is checked.
    // If you want to make something available you added to the token through the jwt() callback,
    // you have to explicitly forward it here to make it available to the client.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async session({ session, user, token }) {
      const customSession = session as CustomSession;
      customSession.user = (token.sessionUser as SessionUser) || null;
      customSession.idToken = token.idToken.toString();
      customSession.idTokenExpiresAt = token.idTokenExpiresAt.toString();

      return customSession;
    },
  },
};

export default (req: NextApiRequest, res: NextApiResponse) => {
  return NextAuth(req, res, authOptions);
};
