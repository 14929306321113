import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  thumb: {
    bg: 'brand.white',
  },
  track: {
    bg: 'blackAlpha.80',
    _checked: {
      bg: 'brand.purple',
    },
    padding: '2px',
  },
});

export const switchTheme = defineMultiStyleConfig({ baseStyle });
