interface IFirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

const publicPages = ['/listings/[a-zA-Z0-9-]+'];

const firebaseConfig: Partial<IFirebaseConfig> | undefined =
  process.env.NEXT_PUBLIC_FIREBASE_CONFIG &&
  JSON.parse(
    Buffer.from(
      `${process.env.NEXT_PUBLIC_FIREBASE_CONFIG}`,
      'base64'
    ).toString()
  );

export default {
  env: process.env.NODE_ENV,
  appEnv: process.env.NEXT_PUBLIC_APP_ENV,
  googleAnalytics: {
    id: process.env.NEXT_PUBLIC_GA_ID,
  },
  sentry: {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  },
  socialMeta: {
    baseUrl: process.env.NEXT_PUBLIC_SOCIALMETA_BASEURL,
    title: process.env.NEXT_PUBLIC_SOCIALMETA_TITLE,
    description: process.env.NEXT_PUBLIC_SOCIALMETA_DESCRIPTION,
    keywords: process.env.NEXT_PUBLIC_SOCIALMETA_KEYWORDS,
    imageUrl: process.env.NEXT_PUBLIC_SOCIALMETA_IMAGEURL,
    companyName: process.env.NEXT_PUBLIC_SOCIALMETA_COMPANYNAME,
    twitterUser: process.env.NEXT_PUBLIC_SOCIALMETA_TWITTERUSER,
  },
  title: 'Equiyd',
  tileColor: '#61219d',
  firebaseConfig,
  legal: {
    termsUrl: process.env.NEXT_PUBLIC_TERMS_URL,
    privacyPolicyUrl: process.env.NEXT_PUBLIC_PRIVACY_POLICY_URL,
  },
  publicPages,
  stripe: {
    publishableKey: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  },
  cookiePolicy: {
    id: process.env.NEXT_PUBLIC_CY_ID,
  },

  // Server-side config (not exposed to the client)
  nextAuthUrl: process.env.NEXTAUTH_URL,
  nextAuthJwtSecret: process.env.NEXTAUTH_JWT_SECRET,
  amplitudeApiKey: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
  intercomAppId: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
  postHogApiKey: process.env.NEXT_PUBLIC_POSTHOG_KEY,
  postHogHost: process.env.NEXT_PUBLIC_POSTHOG_HOST,
};
