import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  text: {
    fontSize: '13px',
    lineHeight: '18px',
    fontWeight: 'bold',
  },
});

const formErrorTheme = defineStyleConfig({
  defaultProps: {
    colorScheme: 'purple',
  },
  baseStyle,
});

export default formErrorTheme;
