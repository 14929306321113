import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const purple = definePartsStyle({
  dialog: {
    bg: 'brand.white',
    position: { base: 'fixed', md: 'relative' },
    mb: 0,
    bottom: 0,
    maxHeight: '100vh',
    overflow: 'auto',
    borderRadius: '25px',
    borderBottomLeftRadius: { base: '0', md: '25px' },
    borderBottomRightRadius: { base: '0', md: '25px' },
    maxWidth: { md: '600px' },
  },
  header: {
    fontWeight: 'normal',
    fontSize: '15px',
    textAlign: 'center',
    py: 'lg',
  },
  closeButton: {
    top: 'md',
    right: 'md',
    _hover: {
      bg: 'blackAlpha.10',
    },
  },
});

export const modalTheme = defineMultiStyleConfig({
  variants: { purple },
});
