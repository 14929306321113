const CheckIcon = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='20' height='20' rx='10' fill='#3A8443' />
    <path
      d='M15.3327 7L7.99935 13.3333L4.66602 10'
      stroke='#F8F8F9'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default CheckIcon;
