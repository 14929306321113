import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  control: {
    borderRadius: '28px', // change the border radius
    borderColor: 'purple.500', // change the border color
    boxSize: '28px', // change the size of the radio
    _checked: {
      backgroundColor: 'white',
      borderColor: 'purple.500',

      _hover: {
        backgroundColor: 'white',
        borderColor: 'purple.500',
      },

      _before: {
        background: 'purple.500',
      },
    },
  },
  container: {
    alignItems: 'start',
  },
  label: {
    marginLeft: 3,
    width: '100%',
  },
});

const radioTheme = defineMultiStyleConfig({ baseStyle });

export default radioTheme;
