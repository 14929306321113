import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  paddingX: '24px',
  paddingY: '14px',
  color: 'blackAlpha.100',
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: '20px',
  _placeholder: {
    color: 'blackAlpha.30',
  },
  borderRadius: '24px',
  focusBorderColor: 'blackAlpha.30',
});

const defaultProps = {
  colorScheme: 'purple',
  focusBorderColor: 'brand.purple',
};

const outline = defineStyle({
  borderColor: 'blackAlpha.30',
});

export const textareaTheme = defineStyleConfig({
  defaultProps,
  baseStyle,
  variants: {
    outline,
  },
});
